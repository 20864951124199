<template>
    <div > 
        <el-container >
            <el-aside width="180px" :height="leftHeight" style="background:#f8f8f9; margin-top:5px;margin-bottom:5px;margin-left:2px; ">
                <div :style="{'display':'flex','flex-direction':'column','height':'700','overflow-y':'auto'}" > 
 
                    <template v-for="(item, index) in menuList" > 
                        <div  class="oneMenu" :key="index" @click="menuClick(index,item.subno)">
                                {{item.cname}}  <i :class="listIndex===index&&menuBool?'el-icon-arrow-up':'el-icon-arrow-down'" style="margin-left:40px;"></i>
                        </div>
                        <template v-for="(subitem ) in item.subs">
                            <span :key="subitem.frmurl"  v-if="openName.indexOf(item.subno)>-1">
                                <div  class="twoMenu">
                                    <span  :class="{active: active===subitem.frmurl}" @click="itemClick(subitem.frmurl,item.cname,subitem.cname,subitem.idseq,subitem.lstseq)">
                                        <i class="iconfont icon-xiayiye1" style="font-size:10px;margin-right:5px;"></i>
                                        {{subitem.cname}} 
                                    </span>
                                </div>
                            </span>
                        </template>
                    </template>  
                </div>
            </el-aside>
            <el-main :style=" {'margin-top':'-14px',width:'1270px','margin-left':'auto','margin-right':'auto'} "  >          
                <div style="font-size:10px; background:#f8f8f9;line-height:40px;height:40px;padding-top:15px;padding-left:5px;border-left:3px solid #409eff">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item  ><span style="color:#409EFF">前端内容维护</span></el-breadcrumb-item>
                        <el-breadcrumb-item  ><span style="color:#409EFF"> {{breadnm}}</span> </el-breadcrumb-item>
 
                    </el-breadcrumb>
                    
                </div> 
 
            </el-main>
        </el-container>
    </div>  
</template>
<script>
 
import { getBaseData } from '../../api/user'
 
//局部注册
export default {
    name:"hot_product",
    data () {
        return {
            menuList: [],
            openName:[], //模块ID集合
            listIndex:'-1',
            active:'', //二级菜单选中标志
            menuBool:false,
            breadnm:'',
            helpSrc:'',
            tinymceHtml:'',
            idseq:'', //菜单主键
            lstseq:'',//菜单主键
            v_username:this.$cookies.get('v_username'), //api 请求用户
            v_password:this.$cookies.get('v_password') ,//api 请求密码
        }
    },
    mounted () {   
        this.getMenuList()
    },
    computed: {
        editHeight: function() {
            return (window.innerHeight - 300) + 'px';
        }, 
        leftHeight: function() {
            return (window.innerHeight - 100) + 'px';
        }, 
    },
    components: {
     
    },
    created () {
        
 
    },
    watch: {
 
    },
    methods: {  
        saveRec(){
            let obj_={'IDSEQ':this.idseq,'LSTSEQ':this.lstseq }
            let vm =this
            this.$axios({
                method: 'post',
                url:vm.$store.state.baseUrl+'sysprivs/tableLobSave?username='+vm.v_username+'&password='+vm.v_password,
                data: {p_idseq:this.idseq, p_lstseq:this.lstseq,p_note:this.tinymceHtml},
            }).then(res=>{   
                if(res.data.result =='OK')  {          
                    this.$Message.info('已保存')
                }else{
                    this.$alert(res.data.result, '提示', {
                        confirmButtonText: '确定',
                        showClose:false,
                        type:'info'
                    })
                }        
            }) 
        },
        //一级菜单点击
        menuClick(index,subno){
            this.listIndex =index
            this.menuBool=!this.menuBool
            if (this.openName.indexOf(subno)===-1){
                this.openName.push(subno)
            }else{
                this.openName.splice(this.openName.indexOf(subno),1)
            }
        },
        //帮助文档菜单
        getMenuList(){
            this.$axios({
                method:'post',
                url: this.$store.state.baseUrl+'auth/login/getSubList' ,
                data:{maintab:'v_bs_front',p_key:'idty',p_value:'FRONT',subtab:'v_bs_frontdtl'},
            }).then(res =>{
                this.menuList =res.data
                //初始化选中第一个
                this.active =res.data[0].subs[0].frmurl
                this.breadnm =res.data[0].cname+' > '+res.data[0].subs[0].cname
 
                for (let k=0;k<res.data.length;k++){
                    this.openName.push(res.data[k].subno)
                } 
            })
        },
        //二级菜单点击事件
        itemClick(frmurl,menu,submenu,idseq,lstseq){
            this.breadnm =menu+' > '+submenu
            this.active =frmurl
            this.idseq =idseq
            this.lstseq=lstseq
            // getBaseData(this.$store.state.baseUrl+'auth/login/getClobData','IDSEQ',idseq,'lstseq',lstseq,'v_bs_frontdtl','').then(res=>{
            //     this.$refs.editor.setContent(res.data.result[0].note)
            // })
        },
 
    }
}
</script>
<style scoped lang="less">
    .el-aside {
        height: calc(100vh - 100px); 
    }
    .oneMenu{
        line-height:35px;
        text-align:left;
        padding-left:35px;
        font-size:14px;
        color:white;
        background-color: #409eff;
    }
    .oneMenu:hover{
        cursor: pointer;
        color:#f4f6f8;
    }
    .twoMenu{
        display:flex;
        flex-direction:column;
        line-height:40px;
        background:white;
        padding-left:35px;
 
    }
    .twoMenu:hover{
        cursor: pointer;
        color:#409EFF;
    }
    .top{
        padding: 10px;
        background: rgba(0, 153, 229);
        color: #fff;
        text-align: center;
        font-size:10px;
        border-radius: 2px;
    }  
    .el-header  {
        background-color: #fff;
        color: #409EFF;
        text-align: center;
        line-height: 60px;
        width:100%;
    }
  .el-main {
    background-color: #fff;
    color: rgb(207, 206, 206);
    text-align: center;
    height:100%;
  }
 
  .footer-css {
        width: 100%;
        position: relative;
        bottom: 0 ;
        background:#f8f8f9;
        height:360px !important;
        vertical-align: center;
        text-align: center;
        color:#409EFF;
        font-weight: 600;
        font-size:12px;
    } 
 
    .active { 
        color:#D200D2; 
    } 
    .wraper{
        width:1250px;
        margin:1px auto;
    }
 
</style>
 